/*******************************
         Site Overrides
*******************************/

.ui.label.team-member-avatar {
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
  padding: 1em 0 !important;
  text-align: center;
  margin-right: 9px;
  margin-left: 0px;
  font-size: 11px;
}

.ui.label.team-member-avatar.big {
  width: 3em;
  height: 3em;
  font-size: 14px;
}

.ui.multiple.dropdown > .label {
  font-weight: normal;
}

.form.inverted .ui.multiple.dropdown > .label  {
  background: #e9e9e9;
  color: #000;
}

.form.inverted .ui.multiple.dropdown > .label, .form.inverted .helperTextLabel  {
  background: #323232;
  color: #fff;
}

.ui.green.labels .label, .ui.green.label {
  background-color: #14432f !important;
  border-color: #14432f !important;
}
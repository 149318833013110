/*******************************
         Site Overrides
*******************************/

.ui.inverted.table {
  border: 1px solid #343434 !important;
}

.ui.selectable.inverted.table tbody tr:hover,
.ui.inverted.table tbody tr td.selectable:hover {
  color: #fff !important;
  background-color: #3d3d3d !important;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) thead tr {
     display: none !important;
  }
}

.ui.modal .ui.compact.table td {
  padding: 0.5em 0.7em;
}

.ui.modal .ui.compact.table th {
  padding: 0.6em 0.7em;
}

.ui.table.inverted tr.active, .ui.table td.active {
  background: #3d3d3d !important;
  color: #fff !important;
}

.ui.selectable.table.inverted tr.active:hover {
  background: #3d3d3d !important;
  color: #fff !important;
}

.ui.inverted.striped.table > tr:nth-child(2n),
.ui.inverted.striped.table tbody tr:nth-child(2n) {
    background-color: inherit;
}
.ui.striped.table > tr:nth-child(2n),
.ui.striped.table tbody tr:nth-child(2n) {
    background-color: inherit;
}

.ui.striped.table > tr:nth-last-child(2n),
.ui.striped.table tbody tr:nth-last-child(2n) {
    background-color: @stripedBackground;
}

.ui.inverted.striped.table > tr:nth-last-child(2n),
.ui.inverted.striped.table tbody tr:nth-last-child(2n) {
    background-color: @invertedStripedBackground;
}

table tfoot {
  background-color: @stripedBackground;
}

table.inverted tfoot {
  background-color: @invertedStripedBackground;
}

.ui.striped.selectable.selectable.selectable.table tbody tr.active:hover {
  background: inherit !important;
  color: inherit !important;
}

.ui.striped.selectable.selectable.selectable.table.inverted tbody tr.active:hover {
  background: #3d3d3d !important;
  color: #fff !important;
}

.ui.table tr:first-child td:focus-within,
.ui.celled.table tr td:focus-within,
.ui.table tr td:focus-within {
  border: 1px solid @cellBorder;
  border-top: 1px solid @cellBorder !important;
}

.ui.table.inverted tr:first-child td:focus-within,
.ui.celled.table.inverted tr td:focus-within,
.ui.table.inverted tr td:focus-within {
  border: 1px solid #343434;
  border-top: 1px solid #343434 !important;
}








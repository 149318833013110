/*******************************
        User Overrides
*******************************/

.field .ui.dropdown {
  font-family: Open Sans, Lato, sans-serif;
}

.ui.selection.dropdown .menu > .item {
  border-top: none;
}

.ui.form.inverted .ui.selection.visible.dropdown > .text:not(.default) {
  color: @invertedInputTextColor;
}

.ui.form.inverted .ui.dropdown:not(.button) > .default.text,
.ui.form.inverted .ui.default.dropdown:not(.button) > .text {
 color: #6b6b6b;
}

.ui.dropdown:not(.button) > .default.text,
.ui.default.dropdown:not(.button) > .text {
 text-transform: lowercase;
}

.ui.search.selection.dropdown > input.search {
  padding: 0.15952381em 0.93333333em !important;
}

.ui.form.inverted .ui.dropdown .menu > .message:not(.ui) {
  color: #fff;
}

.ui.form.inverted .ui.active.search.dropdown input.search:focus + .text {
  color: #fff !important;
}

.ui.inverted.form .ui.selection.dropdown {
  background-color: #343434;
  color: #fff;
}

.ui.inverted.form .ui.dropdown .menu {
  background-color: #343434;
}

.ui.inverted.form .ui.dropdown .menu > .item,
.ui.inverted.form .ui.dropdown .menu .selected.item,
.ui.inverted.form .ui.dropdown .menu > .item:hover {
  color: #fff;
}

.ui.dropdown:not(.button) > .default.text,
.ui.default.dropdown:not(.button) > .text {
  color: #6b6b6b;
}
/*******************************
         Site Overrides
*******************************/

.ui.modal > .header {
  font-family: MarkOT-Medium, Open Sans, sans-serif;
}

.ui.modal {
 background: none;
}

.ui.modal > .inverted.content {
  background-color: #1e1e1e;
}
/*******************************
         Site Overrides
*******************************/

.ui.form.inverted .ui.input {
 color: #fff;
}

.ui.form .inline.fields .field > input, .ui.form .inline.fields .field > select, .ui.form .inline.field > input, .ui.form .inline.field > select {
 width: 100% !important;
}

.ui.form .inline.fields .field > input,
.ui.form .inline.fields .field > select,
.ui.form .inline.field > input,
.ui.form .inline.field > select {
  font-size: 0.9em !important;
}

.ui.input.secondary > input {
  background: transparent;
  font-family: Lato, Open Sans, sans-serif;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

.ui.table .ui.input > input {
 font-family: Lato, Open Sans, sans-serif;
}

.ui.inverted.input > input {
  border: 1px solid #343434;
}

.ui.input.secondary.inverted > input {
  border-color: #343434;
  color: #a9a9a9;
}

.ui.input.secondary.inverted > input::placeholder {
 color: #a9a9a9;
}

.ui.icon.input.secondary.inverted > i.icon {
 color: #a9a9a9;
}

.ui.icon.input.secondary > i.icon {
 right: 5px;
}

.ui.selection.dropdown.secondary {
 font-family: Lato, Open Sans, sans-serif;
 background: transparent;
}

.ui.selection.dropdown.secondary.inverted {
  border-color: #343434;
  color: #fff;
}

.ui.selection.active.dropdown.secondary.inverted:hover,
.ui.selection.dropdown.secondary.inverted:focus {
  border-color: #343434;
  color: #fff;
}

.ui.selection.dropdown.secondary.inverted .menu > .item {
   color: #fff;
   background-color: #1e1e1e;
}

.ui.selection.active.dropdown.secondary.inverted:hover .menu,
.ui.selection.active.dropdown.secondary.inverted:focus .menu,
.ui.selection.dropdown.secondary:hover{
  border-color: #343434;
}

.ui.selection.dropdown.secondary.inverted .menu > .item {
  border-top: 1px solid #343434;
}

.ui.selection.visible.dropdown.secondary.inverted > .text:not(.default) {
 color: #fff;
}

.ui.form.login-form .field > label {
 font-family: MarkOT-Medium, Open Sans, sans-serif;
}

.ui.form.login-form input {
  font-family: MarkOT-Medium, Open Sans, sans-serif !important;
}

.ui.form .field > label {
  font-family: Lato, Open Sans, sans-serif;
}

.form.inverted .ui.radio.checkbox input:checked ~ .box::after, .form.inverted .ui.radio.checkbox input:checked ~ label::after {
  background-color: #fff !important;
}

.ui.form .field.field input:autofill {
  box-shadow: none !important;
}



/*******************************
         Site Overrides
*******************************/

.ui.checkbox {
  font-family: Lato, Open Sans, Lato, sans-serif;
}

.inverted .ui.checkbox .box::before,
.inverted .ui.checkbox label::before {
  color: @solidBorderColor;
}

.inverted .ui.checkbox input:checked ~ .box::before,
.inverted .ui.checkbox input:checked ~ label::before {
  border-color: @solidBorderColor;
}

.inverted .ui.checkbox .box:hover::before,
.inverted .ui.checkbox label:hover::before,
.inverted .ui.checkbox .box:active::before,
.inverted .ui.checkbox label:active::before,
.inverted .ui.checkbox input:focus ~ .box::before,
.inverted .ui.checkbox input:focus ~ label::before {
 border-color: @solidBorderColor;
}

.inverted .ui.checkbox input:checked ~ .box::after,
.inverted .ui.checkbox input:checked ~ label::after {
  color: @solidBorderColor;
}


/*******************************
         Site Overrides
*******************************/

@font-face {
    font-family: 'Mark OT';
    src: url('../assets/fonts/Mark OT.woff2.ttf');
}

@font-face {
    font-family: 'Mark OT';
    src: url('../assets/fonts/MarkOT-Bold.otf');
    font-weight: bold;
}

@font-face {font-family: "MarkOT-Medium";
  src: url("../assets/fonts/MarkOT-Medium/@font-face/450fdebcd9b47d4e245c0272405e0cf2.eot"); /* IE9*/
  src: url("../assets/fonts/MarkOT-Medium/@font-face/450fdebcd9b47d4e245c0272405e0cf2.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../assets/fonts/MarkOT-Medium/@font-face/450fdebcd9b47d4e245c0272405e0cf2.woff2") format("woff2"), /* chrome、firefox */
  url("../assets/fonts/MarkOT-Medium/@font-face/450fdebcd9b47d4e245c0272405e0cf2.woff") format("woff"), /* chrome、firefox */
  url("../assets/fonts/MarkOT-Medium/@font-face/450fdebcd9b47d4e245c0272405e0cf2.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../assets/fonts/MarkOT-Medium/@font-face/450fdebcd9b47d4e245c0272405e0cf2.svg#MarkOT-Medium") format("svg"); /* iOS 4.1- */
}

main {
  padding: .2em 2em;
  padding-bottom: 50px;
}

.modals .content > .segment:first-child:not(:last-child) {
  padding-bottom: 0rem;
}

.modals .content .segment {
  padding: .3em 1em 1em;
  margin-top: .3rem;
}

.modals h4.header {
  margin-bottom: .3rem;
}

.modals .segment h4.header:first-of-type {
  margin-top: 1.1rem;
}

@media screen and (max-width: 920px) {
  main {
    padding-left: .2em;
    padding-right: .2em;
  }
}

/*******************************
        User Overrides
*******************************/

.ui.accordion .title:not(.ui) .ui.header {
  font-family: MarkOt-Medium, Open Sans, sans-serif;
}

.ui.accordion .title, .ui.accordion .accordion .title {
  width: max-content;
}

/*******************************
         Site Overrides
*******************************/

.ui.inverted.menu .item {
  color: #a9a9a9;
  font-size: 1.3rem;
}

.ui.inverted.tabular.menu .item, .ui.inverted.tabular.menu .active.item:not(:last-child) {
  border-right: 1px solid #e9e9e9;
}

.ui.tabular.menu .item, .ui.tabular.menu .active.item {
  border-right: 1px solid #000;
}


.ui.tabular.menu .item:last-of-type {
  border-right: none;
}

.ui.tabular.menu .item {
  padding: 0px 4px;
  font-size: 1.2rem;
}

.ui.tabular.menu .active.item {
  font-size: 1.3rem;
  font-weight: bold;
}

.ui.tabular.menu .item:first-of-type {
  padding-left: 0px;
}

.ui.menu.navigation-menu, .ui.menu.navigation-menu-opener {
  margin-top: -40px;
  margin-bottom: 30px;
  margin-left: 1em;
  background-color: transparent;
  width: calc(100vw - 2em - 90px) !important;
  flex-wrap: wrap;
}

.ui.menu.navigation-menu .active.item {
 background-color: transparent;
}

.ui.menu.navigation-menu-opener {
  display: none;
}

.ui.menu.navigation-menu-opener .ui.dropdown {
  font-family: MarkOT-Medium, Open Sans, sans-serif;
  text-transform: lowercase;
  font-size: 1.3rem;
}


.ui.menu.navigation-menu-opener .ui.dropdown .item, .ui.menu.navigation-menu-opener .ui.dropdown .selected.item {
  text-transform: lowercase !important;
}

.ui.menu.inverted.navigation-menu-opener .ui.dropdown .menu {
  background-color: #1e1e1e;
  width: 100vw;
  margin-top: 13px;
  margin-left: -1.5em;
}

.ui.menu.inverted.navigation-menu-opener .ui.dropdown .menu > .item:hover,
.ui.menu.inverted.navigation-menu-opener .ui.dropdown .item,
.ui.menu.inverted.navigation-menu-opener .ui.dropdown .selected.item {
  color: #fff !important;
}

@media screen and (max-width: 960px) {
  .ui.menu.navigation-menu {
    display: none;
  }

  .ui.menu.navigation-menu-opener {
    display: block;
  }
}
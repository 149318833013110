/*******************************
         Site Overrides
*******************************/

.ui.form ::placeholder {
  font-family: Open Sans, Lato, sans-serif;
  text-transform: lowercase;
  color: #6b6b6b;
  opacity: 1;
}

ui.form .inline.fields .field > :first-child, .ui.form .inline.field > :first-child {
  margin: 0em;
}

.ui.form.login-form ::placeholder {
  font-family: MarkOT-Medium, Lato, Open Sans, sans-serif;
}

.ui.form .fields {
  margin: 0 0 .4em;
}

.ui.form .inline.field > label {
  min-width: 140px;
}

.ui.form .inline.field > .selection.dropdown {
  width: 100%;
}

textarea {
  font-family: MarkOt-Medium, Open Sans, sans-serif;
}

.ui.form .field.project-settings-field {
  display: inline-block;
  min-width: 400px;
  margin-bottom: 0;
  margin-top: 10px;
}

@media screen and (max-width: 400px) {
  .ui.form .field.project-settings-field {
    min-width: 250px;
  }
}

.inverted .ui.form textarea:focus,
.inverted .ui.form textarea {
  background-color: @invertedInputBackgroundColor;
  color: #a9a9a9;
}

.ui.form .inline.field > label {
  line-height: 1.6rem;
}

.ui.form .inline.fields .field > input,
.ui.form .inline.fields .field > select,
.ui.form .inline.field > input,
.ui.form .inline.field > .input input,
.ui.form .inline.field > select,
.ui.form .inline.field input,
.ui.form .ui.selection.dropdown {
  font-weight: 800;
}
